import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Link, graphql, StaticQuery } from 'gatsby'

class TagsPopuler extends React.Component {
  render() {
    const { data: { allMarkdownRemark: { group } } } = this.props

    return (
                      <div className="taglist" style= {{ height: '140px', overflow: 'hidden' }}>
                        {group.slice(0,10).map(tag => (
                          <li key={tag.fieldValue} style={{ fontSize: '12', padding: '0.15rem 0.65rem'}}>
                            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                              #{tag.fieldValue}
                            </Link>
                          </li>
                        ))}
                      </div>
    )
  }
}

TagsPopuler.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query TagsPopulerQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 10
        ) {
          group (field: frontmatter___tags) {
            fieldValue
          }
        }
      }
    `}
    render={(data, count) => <TagsPopuler data={data} count={count} />}
  />
)

