import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BeritaTerkini extends React.Component {
  render() {
    const { data } = this.props
    const { edges: mdFiles } = data.allMarkdownRemark

    return (
      <div className="terkini2">
        {mdFiles &&
          mdFiles.map(({ node: post }) => (
            <div className="is-parent" key={post.id}>
              <article
                style={{boxShadow: '0 0 16px rgba(43, 37, 35, 0.1)', padding: '7px 20px', textAlign: 'left' }}
                className={`blog-list-item tile is-child box clearfix ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                <header className="terkini2-meta">
                  {post.frontmatter.featuredimage ? (
                    <Link
                      className="title has-text-primary"
                      to={post.fields.slug}
                    >
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                    </Link>
                  ) : null}
                  <p className="post-meta">
                    <Link
                      className="title has-text-primary"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <span className="subtitle is-size-6 is-block">
                      🕙 {post.frontmatter.date}&nbsp;| <br className="som" /> 
                      <Link to={`/post-categories/${post.frontmatter.kategori[0].toLowerCase()}/`}>
                      <span>
                      {post.frontmatter.kategori[0]}
                      </span>
                      </Link>
                    </span>
                  </p>
                </header>
              </article>
            </div>
          ))}
        <div className="column is-12 has-text-centered"  style={{ background: '#000', boxShadow: '0 0 16px rgba(43, 37, 35, 0.1)' }}>

                    <Link className="btn" to="/builds">
                      See More
                    </Link>
          </div>
      </div>
    )
  }
}

BeritaTerkini.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BeritaTerikiniQuery {
        allMarkdownRemark (
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          skip: 1
          limit: 6
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD-MM-YY")
                featuredpost
                kategori
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 60, quality: 10) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BeritaTerkini data={data} count={count} />}
  />
)
