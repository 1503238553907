import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import TwitchEmbedComponent from '../components/TwitchEmbedComponent'
import { YTvideos } from '../components/ytvideos'
import { TwitchClip } from 'react-twitch-embed';
import Features from '../components/Features'
import BlogRoll1 from '../components/BlogRoll1'
import TagsPopuler from '../components/TagsPopuler'
import BeritaTerkini from '../components/Terkini'
import BeritaTerkiniSekretariat from '../components/TerkiniSekretariat'
import BeritaTerkiniDafduk from '../components/TerkiniDafduk'
import BeritaTerkiniCapil from '../components/TerkiniCapil'
import BeritaTerkiniPiak from '../components/TerkiniPiak'
import BeritaTerkiniBintur from '../components/TerkiniBintur'
import BeritaTerkiniFPD2K from '../components/TerkiniFPD2K'
import BeritaTerkiniPers from '../components/TerkiniPers'
import BeritaTerkiniArtikel from '../components/TerkiniArtikel'


import { Carousel } from 'react-responsive-carousel';
import { FacebookProvider, Page } from 'react-facebook';

import '../components/global.css'

import tati from '../../static/tati.webp'
import img1 from '../../static/img/img1.webp'
import img2 from '../../static/img/img2.webp'
import img3 from '../../static/img/img3.webp'
import img4 from '../../static/img/img4.webp'
import img5 from '../../static/img/img5.webp'
import img9 from '../../static/img/img9.webp'
import img10 from '../../static/img/img10.webp'
import img11 from '../../static/img/img11.webp'
import img12 from '../../static/img/img12.webp'
import pengaduan from '../../static/img/pengaduan.webp'


export const IndexPageTemplate = ({
  clips,
  title,
  situs,
}) => (
  <div>
      
    <section className="section section--gradient">  
      <div className="container">
        <div className="content">
          <TwitchEmbedComponent/>
        </div>
      </div>
    </section>

    <section className="section section--gradient">
      <div className="container">
              <div className="content">
                  <div className="col-container" style={{backgroundColor:'#000', display: 'flex', alignItems:'center', minHeight: 250}} >
                      <div  className="slider1" style={{color:'#FFFF', fontWeight:'500', padding:'0 40px'}}>
                       <p>Hey, I'm Tati, a 30 year old Siberian gamer, I love playing Path of Exile, especially Totem builds. Discovering new different mechanics for all kinds of Totem builds (Spell Totems, Ballistae, Ancestral Totems) is a lot of fun for me, and I always try to share my builds with other Totem enjoyers from the community.</p>
                       <p>I prefer playing Hardcore and Solo Self-found modes, but from time to time I could also do a min-maxed Softcore-trade build. I always stream all my progress on Twitch, as well as making Totem build guides and highlights for Youtube.</p>
                      </div>

                      <div className="slider2">
                        <img src={tati} />
                      </div>

                      <div className='bagidua'>
                        <div className='bagidua'>
                          
                        </div>
                        <div className='bagidua'>

                        </div>
                      </div>

                      <div className='bagidua'>
                        <div className='bagidua'>
                          
                        </div>
                        <div className='bagidua'>

                        </div>
                      </div>
                      
                  </div>
              </div>
          </div>
      </section>
      <section className="section section--gradient">
          <div className="container">
                  <div className="content">
                      <YTvideos/>
                  </div>
          </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="content">
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    RECENT BUILDS
                  </h3>
                  <div className="col-container">
                      
                    <BlogRoll1 />
                    <BeritaTerkini />
                      <div className="terkini3" style={{ padding: '10px 20px', background: '#000',  boxShadow: '0 0 16px rgba(43, 37, 35, 0.1)' }}>
                        <p>Tags: </p>
                        <TagsPopuler />
                      </div>
                  </div>
                </div>
          </div>
        </div>
      </section>

      <section className="section section--gradient">
          <div className="container">
                  <div className="content">
                  <h3 className="has-text-weight-semibold is-size-2">
                    STREAM HIGHLIGHTS
                  </h3>
                    <div className="PostSection--Grid" style={{width:"100%", gridGap:"1rem", backgroundColor:"black"}}>
                      {clips.map(clip => (
                        <div>
                        <TwitchClip clip={clip.url} autoplay="false" muted="false" width="100%" height="270" allowfullscreen="true" />
                        </div>
                      ))}

                    </div>
                  </div>
          </div>
      </section>

  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        clips={frontmatter.clips}
        situs={frontmatter.situs}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`

  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        clips {
          teks
          url
        }
        situs {
          teks
          url
        }
      }
    }
  }
`