import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'


class BeritaTerkiniSekretariat extends React.Component {
  render() {
    const { data } = this.props
    const { edges: mdFiles } = data.allMarkdownRemark

    return (
      <div>
        {mdFiles &&
          mdFiles.map(({ node: post }) => (
            <div className="is-parent" key={post.id}>
              <article
                style={{ padding: 0 }}
                className={`blog-list-item tile is-child clearfix ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                <header style={{ marginBottom: '0', padding: '10px 0', borderBottom: 'solid 1px #ececec' }}>
                  <p className="post-meta">
                    <Link
                      className="title has-text-primary"
                      style={{ fontSize: '14px', color:'#000' }}
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                  </p>
                </header>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

BeritaTerkiniSekretariat.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BeritaTerikiniSekretariatQuery {
        allMarkdownRemark (
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" }, kategori: {eq: "SEKRETARIAT"}}}
          limit: 4
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 60, quality: 10) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <BeritaTerkiniSekretariat data={data} />}
  />
)
