import React, { useRef } from 'react';
import { TwitchEmbed } from 'react-twitch-embed';

const TwitchEmbedComponent = () => {
  const embed = useRef(); // We use a ref instead of state to avoid rerenders.
  
  const handleReady = (e) => {
    embed.current = e;
  };
  
  return (
    <TwitchEmbed channel="tatiantel2" autoplay={true} muted={false} allowfullscreen={true} withChat width="100%" darkMode={true} hideControls={false} onVideoReady={handleReady} />
  );
};

export default TwitchEmbedComponent;