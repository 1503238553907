import React, {useEffect, useState} from "react"

const API="AIzaSyBuFlOBkmEmxbhHva5SgkwMS2peed-hP2Q"
const channelId="UCqj6tuMpyQ4j48OTlRFbV8Q"

var fetchurl = `https://www.googleapis.com/youtube/v3/search?key=${API}&channelId=${channelId}&part=snippet,id&order=date&maxResults=6`

export const YTvideos=()=>{
    const [allvideos, setAllvideos] = useState( [] )
    useEffect(()=>{
        fetch(fetchurl).then((response)=> response.json()).then((resJson)=>{
            const result = resJson.items.map(doc=>({
                ...doc,
                Videolink: "https://www.youtube.com/embed/"+doc.id.videoId
            }));
            setAllvideos(result)
        })
    }, [])

    return (
        <div className="PostSection--Grid" style={{width:"100%", gridGap:"0.5rem"}}>
            {allvideos.map((item)=>{
                return(
                    <div>
                        <iframe width="100%" height="270" src={item.Videolink} title="Youtube Video Player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                    </div>
                );
            })}

        </div>
    )
}