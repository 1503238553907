import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll1 extends React.Component {
  render() {
    const { data } = this.props
    const { edges: mdFiles } = data.allMarkdownRemark

    return (
      <div className="terkini1">
        {mdFiles &&
          mdFiles.map(({ node: post }) => (
            <div style={{ height: '100%'}} key={post.id}>
              <article
                style={{ height: '100%'}}
                className={`tile is-child box notification ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                <div>
                  <Link
                      className="title has-text-primary is-size-5"
                      to={post.fields.slug}
                    >
                  {post.frontmatter.featuredimage ? (
                    <div className="">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                  </Link>
                  <br />
                  <p className="post-meta">
                    <Link
                      className="title has-text-primary is-size-5"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <br />
                    <span className="subtitle is-size-6 is-block">
                      🕙 {post.frontmatter.date}&nbsp;&nbsp;|&nbsp;&nbsp;
                      <Link to={`/post-categories/${post.frontmatter.kategori[0].toLowerCase()}/`}>
                      <span>
                      🏷️ {post.frontmatter.kategori[0]}
                      </span>
                      </Link>

                    </span>
                    
                  </p>
                </div>
                <p style={{ marginTop: '15px'}}>
                  {post.excerpt}
                </p>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll1.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRoll1Query {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 1
        ) {
          edges {
            node {
              excerpt(pruneLength: 340)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD-MM-YYYY")
                featuredpost
                kategori
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 250, quality: 20) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll1 data={data} count={count} />}
  />
)
